import axios from 'axios';
import { store } from 'store';
import { cleanUp, clearToken, loginSuccess } from 'store/auth/actions';
import { getAuthIsAuthenticated, getAuthToken } from 'store/auth/selectors';
import { api } from './api';

let refreshTokenPromise = null;
let pendingRequestsCount = 0;

const http = axios.create({
    baseURL: process.env.REACT_APP_WEB_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

if (process.env.NODE_ENV === "development") {
    window.http = http
}

const ProcessByQueue = (config) => new window.Promise(resolve => {
    refreshTokenPromise.then((resp) => {
        pendingRequestsCount++;
        resolve(http.request(config));
        return resp;
    });
}).then(res => {
    pendingRequestsCount--;
    if (pendingRequestsCount <= 0) refreshTokenPromise = null;
    return res;
}).catch(async () => {
    await clearToken();
});

const requestInterceptor = (config) => {
    let auth = getAuthToken(store.getState());
    if (auth) {
        config.headers.Authorization = `${auth.token_type} ${auth.access_token}`;
    }
    return config;
};

http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        let { response } = error;
        // Если произошла ошибка токена
        if (response && response.status === 401) {
            const state = store.getState();
            if (refreshTokenPromise) {
                // Если токен уже обновляется, просто ставим в очередь
                return ProcessByQueue(response.config);
            } else if (getAuthIsAuthenticated(state)) {
                store.dispatch(cleanUp());

                // Если 401 и токен еще не обновляется, то рефрешим токен
                const auth = getAuthToken(state);
                if (process.env.NODE_ENV !== 'production') {
                    const currentTime = new Date().toLocaleTimeString();
                    console.log(`[HTTP/${response.status}] Refresh tokens at ${currentTime}`);
                }
                refreshTokenPromise = api.auth.refreshToken(auth.refresh_token);
                refreshTokenPromise.then((resp) => {
                    if (resp.status === 200) {
                        if (process.env.NODE_ENV !== 'production') {
                            const currentTime = new Date().toLocaleTimeString();
                            console.log(`Refresh tokens updated at ${currentTime}`);
                        }
                        // Если новый токен пришел, то сохраняем его в хранилище
                        store.dispatch(loginSuccess({ auth: resp.data }));
                    }
                    return resp;
                });
                // Добавляем неудавшийся запрос в очередь
                return ProcessByQueue(response.config);
            }
        } else if (response.status === 422) {
            if (response.config.url.endsWith('/refresh-token')) {
                store.dispatch(clearToken());
                store.dispatch(cleanUp());
            }
        } else if (response.status === 400) {
            if (response.config.url.endsWith('/refresh-token')) {
                store.dispatch(clearToken());
                store.dispatch(cleanUp());
            }
        }
        throw error;
    });

http.interceptors.request.use(requestInterceptor);

export { http }