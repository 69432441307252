const PRFX = '@auth/';

export const LOGIN_REQUEST = `${PRFX}LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${PRFX}LOGIN_SUCCESS`;
export const LOGIN_FAIL = `${PRFX}LOGIN_FAIL`;

export const LOGOUT_REQUEST = `${PRFX}LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${PRFX}LOGOUT_SUCCESS`;
export const LOGOUT_FAIL = `${PRFX}LOGOUT_FAIL`;

export const REFRESH_TOKEN = `${PRFX}REFRESH_TOKEN`;
export const CLEAR_TOKEN = `${PRFX}CLEAR_TOKEN`;
export const CLEAN_UP = `${PRFX}CLEAN_UP`;

export const SET_TABLE_ID = `${PRFX}SET_TABLE_ID`;
