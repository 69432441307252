import { createSelector } from 'reselect';

/*
|--------------------------------------------------------------------------
| State piece getters
|--------------------------------------------------------------------------
*/
const _getAuth = (state) => state.auth;

/*
|--------------------------------------------------------------------------
| Selectors
|--------------------------------------------------------------------------
*/
export const getAuth = createSelector(
	[_getAuth],
	(auth) => auth
);

export const getAuthIsAuthenticated = createSelector(
	[getAuth],
	(auth) => auth.isAuthenticated
);

export const getAuthIsRefreshingToken = createSelector(
	[getAuth],
	(auth) => auth.isRefreshingToken
);

export const getAuthIsLoggingIn = createSelector(
	[getAuth],
	(auth) => auth.isLoggingIn
);

export const getAuthPermissions = createSelector(
	[getAuth],
	(auth) => auth.permissions || []
);

export const getAuthToken = createSelector(
	[getAuth],
	(auth) => auth.token
);

export const getSip = createSelector(
	[getAuth],
	(auth) => auth.sip
);

export const getTableId = createSelector(
	[getAuth],
	(auth) => auth.tableId
);

export const getLoggingFail = createSelector(
	[getAuth],
	(auth) => auth.loggingFail
);
