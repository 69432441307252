import React from 'react'

export const Popup = React.memo(({ header, content }) => {
    return (
        <div id="popup1" className="overlay">
            <div className="popup">
                <h2>{header}</h2>
                <div className="content">
                    {content}
                </div>
            </div>
        </div>
    )
})