import { createSelector } from 'reselect';

/*
|--------------------------------------------------------------------------
| State piece getters
|--------------------------------------------------------------------------
*/
const _getOrders = (state) => state.orders;

/*
|--------------------------------------------------------------------------
| Selectors
|--------------------------------------------------------------------------
*/
export const getOrders = createSelector(
    [_getOrders],
    (orders) => orders.list || []
);

export const getHotOrdersLength = createSelector(
    [_getOrders],
    (orders) => orders.hotOrders
);

export const getTotalOrders = createSelector(
    [_getOrders],
    (orders) => orders.totalOrders
);

export const _getSearchedOrdersList = createSelector(
    [_getOrders],
    (orders) => orders.searchedList
);

export const getSearchedOrdersData = createSelector(
    [_getSearchedOrdersList],
    (searchedList) => searchedList.data
);

export const getSearchedOrdersStatus = createSelector(
    [_getSearchedOrdersList],
    (searchedList) => searchedList.active
);

export const getCashboxStatus = createSelector(
    [_getOrders],
    (orders) => orders.isCashboxOpen
);
