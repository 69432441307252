import { store } from 'store';
import { fetchOrders, saveCashboxStatus } from 'store/orders/actions';
import { ws as WS } from 'utils/socket';

export const socketConnect = async (token) => {
	if (token.token_type && token.access_token) {
		const ws = await WS(token.token_type, token.access_token);

		await ws.subscribe(`private-orders-${token.restaurant_slug}`);
		await ws.on(`private-orders-${token.restaurant_slug}`, 'collector-list-changed', (response) => {
			if (response.resource) {
				store.dispatch(fetchOrders());
			}
		});

		await ws.subscribe(`private-cashbox-${token.restaurant_slug}`);
		await ws.on(`private-cashbox-${token.restaurant_slug}`, 'cash-shift-closed', (response) => {
			if (response.resource) {
				store.dispatch(saveCashboxStatus(false));
			}
		});
		await ws.on(`private-cashbox-${token.restaurant_slug}`, 'cash-shift-opened', (response) => {
			if (response.resource) {
				store.dispatch(saveCashboxStatus(true));
			}
		});
	}
};

export const socketDisconnect = async () => {
	const ws = await WS();
	await ws.disconnect();
};
