import React, { useEffect, useState } from 'react'
import logo from 'assets/images/logo.svg';
import search from 'assets/images/search.svg';
import tablets from 'assets/images/tablets.png';
import logout from 'assets/images/logout.png';
import cros from 'assets/images/cros.svg';
import { getHotOrdersLength, getTotalOrders } from 'store/orders/selectors';
import { fetchOrders, fetchSearchedOrders, setStatusOfSearchedOrders, fetchCashboxStatus } from 'store/orders/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { cleanUp, clearToken } from 'store/auth/actions';

function Header(props) {
    const { hotOrders, fetchOrders, totalOrders, fetchSearchedOrders, setStatusOfSearchedOrders, fetchCashboxStatus, setSettingModal, cleanUp, clearToken } = props;
    const [inputVal, setInputVal] = useState("")

    useEffect(() => {
        fetchCashboxStatus()
    }, [fetchCashboxStatus])

    useEffect(() => {
        fetchOrders()
    }, [fetchOrders])


    useEffect(() => {
        if (inputVal.trim()) {
            fetchSearchedOrders(inputVal)
            setStatusOfSearchedOrders(true)
        } else {
            setStatusOfSearchedOrders(false)
        }
    }, [inputVal, setStatusOfSearchedOrders, fetchSearchedOrders])

    return (
        <div className="header">
            <div className="logo">
                <div className="logo__img">
                    <img src={logo} alt="logo" />
                </div>
            </div>
            <div className="search">
                <div className="search__input">
                    <img src={search} alt="search" />
                    <input type="text" placeholder="Поиск заказа по номеру" onChange={(e) => setInputVal(e.target.value)} value={inputVal} />
                    {inputVal ? <img src={cros} alt="cros" onClick={() => setInputVal("")} /> : null}
                </div>
            </div>
            <div className="info">
                <p className="info__all">всего: {totalOrders}</p>
                <p className="info__hot">горящих: {hotOrders}</p>
            </div>
            <div className="settings">
                <img src={tablets} alt="settings" onClick={() => { setSettingModal(true) }} />
            </div>
            <div className="logout">
                <img src={logout} alt="logout" onClick={() => {
                    clearToken()
                    cleanUp()
                }} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        hotOrders: getHotOrdersLength(state),
        totalOrders: getTotalOrders(state),
    }
};

const mapDispatchToProps = {
    fetchOrders, fetchSearchedOrders, setStatusOfSearchedOrders, fetchCashboxStatus, clearToken, cleanUp
};

const EnhancedHeader = compose(
    connect(mapStateToProps, mapDispatchToProps),
)(Header);

export { EnhancedHeader };