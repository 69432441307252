const PRFX = `@orders/`

export const FETCH_ORDERS = `${PRFX}FETCH_ORDERS`;
export const SAVE_ORDERS = `${PRFX}SAVE_ORDERS`;

export const SET_ORDER_PACKED = `${PRFX}SET_ORDER_PACKED`;

export const FETCH_SEARCHED_ORDERS_LIST = `${PRFX}FETCH_SEARCHED_ORDERS_LIST`;
export const SAVE_SEARCHED_ORDERS_LIST = `${PRFX}SAVE_SEARCHED_ORDERS_LIST`;

export const CHANGE_SEARCHED_LIST_ACTIVE_STATUS = `${PRFX}CHANGE_SEARCHED_LIST_ACTIVE_STATUS`;
export const SAVE_SEARCHED_LIST_ACTIVE_STATUS = `${PRFX}SAVE_SEARCHED_LIST_ACTIVE_STATUS`;

export const FETCH_CASHBOX_STATUS = `${PRFX}FETCH_CASHBOX_STATUS`;
export const SAVE_CASHBOX_STATUS = `${PRFX}SAVE_CASHBOX_STATUS`;