import { createStore, applyMiddleware, compose } from "redux";
import { rootReducer as root } from './root-reducer'
import createSagaMiddleware from 'redux-saga'
import { sagas } from "./root-saga";

const devTools = process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : null

const sagaMiddleware = createSagaMiddleware()

let enhancers = applyMiddleware(sagaMiddleware);
if (devTools) enhancers = compose(applyMiddleware(sagaMiddleware), devTools)

export const store = createStore(root, enhancers);

sagaMiddleware.run(sagas)