import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './components';
import * as serviceWorker from './serviceWorker';
import { store } from 'store'
import { Provider } from 'react-redux';
import { restoreAuth } from 'store/restoreAuth';

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));

restoreAuth();

window.addEventListener('storage', (e) => {
    if (e.key === 'auth') {
        restoreAuth();
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
