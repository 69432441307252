import { orders } from './orders';
import { auth } from './auth';

export const api = {
    orders,
    auth,
};

if (process.env.NODE_ENV !== 'production') {
    window.$api = api;
}