import * as orders from './types';
import { createReducer } from 'utils/store';

const initialState = {
    list: [],
    searchedList: {
        active: false,
        data: [],
    },
    hotOrders: 0,
    totalOrders: 0,
    isCashboxOpen: false,
};

export const reducer = createReducer(initialState, {
    [orders.SAVE_ORDERS](state, action) {
        const { payload } = action
        return {
            ...state,
            list: payload.orders,
            hotOrders: payload.hot,
            totalOrders: payload.total,
        };
    },

    [orders.SAVE_SEARCHED_ORDERS_LIST](state, action) {
        const { payload } = action
        return {
            ...state,
            searchedList: {
                ...state.searchedList,
                data: payload.data,
            }
        };
    },
    [orders.SAVE_SEARCHED_LIST_ACTIVE_STATUS](state, action) {
        const { payload } = action
        return {
            ...state,
            searchedList: {
                ...state.searchedList,
                active: payload,
            }
        };
    },
    [orders.SAVE_CASHBOX_STATUS](state, action) {
        const { payload } = action;
        return {
            ...state,
            isCashboxOpen: payload,
        };
    },
})
