import { getLocalStorage } from 'utils/local-storage';
import { store } from './index';
import { loginSuccess, clearToken } from './auth/actions';

/*
|--------------------------------------------------------------------------
| @TODO => НАДО СДЕЛАТЬ КАК-ТО ПО-ДРУГОМУ, ПОТОМУ ЧТО НЕ БЕЗОПАСНО!
|--------------------------------------------------------------------------
| Это решает вопрос каждоразового запроса на рефреш токен при загрузке страницы, но это совсем не секьюрно
| Идея взята и доработана отсюда:
| https://github.com/mjrussell/react-redux-jwt-auth-example/blob/master/src/index.js#L15
*/
export const restoreAuth = () => {
	const auth = getLocalStorage('auth');
	const tableId = window.localStorage.getItem("tableId");
	if (auth && tableId) {
		store.dispatch(loginSuccess({ auth, tableId }));
	} else {
		store.dispatch(clearToken());
	}
};
