/**
 * Кодируем и декодируем строку на основанни сдвига позиции символа строки
 * !!!ВАЖЖНО!!! значение параметра `shift` должно быть всегда одним и тем же числом.
 * Меняется только направление сдвига, если `shift` положительное или отрицательное
 * @param string - строка которую кодируем.
 * @param shift - занчение сдвига.
 * @private
 */
const _code = (string, shift) => string.split('').map(c => String.fromCharCode(c.charCodeAt() + +shift)).join('');

export const removeLocalStorage = (key = null) => {
    window.localStorage.removeItem(key)
};

export const getLocalStorage = (key = null) => {
    if (!key) return null;
    const LSItem = window.localStorage.getItem(key);
    if (!LSItem) return null;
    try {
        return JSON.parse(_code(LSItem, 1));
    } catch (error) {
        removeLocalStorage(key);
        return null;
    }
};

export const setLocalStorage = (key = null, data = null) => {
    if (!key || !data) return null;
    window.localStorage.setItem(key, _code(JSON.stringify(data), -1));
};