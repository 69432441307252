import * as auth from './types';
import { createReducer } from 'utils/store';

const initialState = {
	isAuthenticated: false,
	isRefreshingToken: false,
	isLoggingIn: false,
	token: null,
	tableId: null,
	loggingFail: false,
};

export const reducer = createReducer(initialState, {
	[auth.LOGIN_REQUEST](state) {
		return {
			...state,
			isLoggingIn: true
		};
	},

	[auth.LOGIN_SUCCESS](state, action) {
		const { payload } = action;
		const { auth, tableId } = payload
		// eslint-disable-next-line
		const { permissions = {}, restaurant_slug, sip, ...token } = auth;

		return {
			...state,
			token: token,
			isLoggingIn: false,
			isAuthenticated: true,
			isRefreshingToken: false,
			tableId: tableId ? tableId : state.tableId,
			loggingFail: false,
		};
	},

	[auth.LOGIN_FAIL](state) {
		return {
			...state,
			isLoggingIn: false,
			loggingFail: true,
		};
	},

	[auth.REFRESH_TOKEN](state) {
		return {
			...state,
			isRefreshingToken: true
		};
	},

	[auth.CLEAR_TOKEN](state) {
		return initialState;
	},

	[auth.SET_TABLE_ID](state, action) {
		const { payload } = action;
		return {
			...state,
			tableId: payload,
		};
	}
});
