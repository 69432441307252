import React from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getTableId } from 'store/auth/selectors'
import { setTableId } from 'store/auth/actions'
import { fetchOrders } from 'store/orders/actions'
import tablet from 'assets/images/choice-tablet.png';

function Modal(props) {
    const { setSettingModal, tableId, setTableId, fetchOrders } = props
    const tablets = []
    for (let i = 0; i < (tableId > 4 ? tableId : 4); i++) {
        tablets[i] = (
            <div
                key={i}
                style={{ filter: +tableId === i + 1 ? "sepia(1)" : "unset" }}
                className="tablet"
                onClick={() => {
                    setTableId(i + 1)
                    setSettingModal(false)
                    fetchOrders()
                }}>
                <div className="tablet__num">{i + 1}</div>
                <img src={tablet} alt="tablet" />
            </div>
        )
    }
    return (
        <div className="modal-settings">
            <div className="modal-settings__container">
                <span id="x" onClick={() => setSettingModal(false)}>X</span>
                <div className="modal-settings__text">Выберите планшет</div>
                <div className="modal-settings__info">Выбран: {tableId}</div>
                <div className="tablets">
                    {tablets}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        tableId: getTableId(state)
    }
};

const mapDispatchToProps = {
    setTableId,
    fetchOrders
};

const EnhancedModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
)(Modal);

export { EnhancedModal };