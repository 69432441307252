import React from 'react'
import notFound from 'assets/images/no-results.jpg';

export const NotFoundSearch = React.memo(() => {
    return (
        <div className="no-results">
            <img src={notFound} alt="not found" />
        </div>
    )
})
