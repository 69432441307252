import { call, put, takeEvery, delay, select, takeLatest } from 'redux-saga/effects';
import * as authTypes from './types';
import { loginSuccess, clearToken, cleanUp, refreshTokenRequest, setTableId } from './actions';
import { api } from 'services';
import { setLocalStorage, removeLocalStorage } from 'utils/local-storage';
import { socketConnect, socketDisconnect } from 'store/socket/actions';
// import { deleteUser } from 'store/redux/user/actions';
import { store } from 'store';
import { getAuthToken, getTableId } from './selectors';

const saveTokenToLocalStorage = (auth) => setLocalStorage('auth', auth);
const removeTokenFromLocalStorage = () => removeLocalStorage('auth');

const saveTableIdToLocalStorage = (tableId) => window.localStorage.setItem("tableId", tableId)
const removeTableFromLocalStorage = () => removeLocalStorage('tableId');

function* loginRequestSaga(action) {
	const { payload } = action;
	const { auth, tableId } = payload;
	try {
		const response = yield call(api.auth.login, auth);
		yield put(loginSuccess({ auth: response.data, tableId }));
	} catch (error) {
		yield put({ type: authTypes.LOGIN_FAIL });
	}
}

function* loginSuccessSaga(action) {
	const { payload = {} } = action;
	let { auth: payloadAuth, tableId } = payload;
	if (!tableId) {
		tableId = yield select(getTableId)
	}
	yield saveTableIdToLocalStorage(tableId);
	yield put(setTableId(tableId));
	yield saveTokenToLocalStorage(payloadAuth);
	try {
		// const response = yield call(api.sip.checkCurrentUserAccount);
		// if (sip.enabled && sip.login && sip.pass && response.data.success) {
		// 	yield put(sipInit(true));
		// 	yield call(sipConnect, { login: sip.login, password: sip.pass });
		// }
	} catch (error) { }
	yield call(socketConnect, payloadAuth);
	/*
	|--------------------------------------------------------------------------
	| Внимание
	|--------------------------------------------------------------------------
	| Код ниже будет нормально работать только с одной вкладкой в браузере
	| Если будет открыто более одной вкладки и они примерно в одно и тоже время сделают refreshTokenRequest
	| Приложение упадет, т.к. в итоге на одной из вкладок токен будет невалидный и нужно будет перелогиниться
	|
	| Нормально это будет рабоать, к примеру, в PWA или десктоп приложении
	| При уловии что под одним аккаунтом работает один пользователь
	*/
	if (payloadAuth.expires_in >= 15) {
		// Принудительно обновляем токен за 10 секунд до сгорания
		yield delay((payloadAuth.expires_in - 10) * 1000);
		if (yield select(getAuthToken)) {
			const auth = getAuthToken(store.getState());
			if (process.env.NODE_ENV !== 'production') {
				const currentTime = new Date().toLocaleTimeString();
				console.log(`[Auth/Saga::loginSuccessSaga] Refresh tokens at ${currentTime}`);
			}
			yield put(cleanUp());
			yield put(refreshTokenRequest({ auth: auth.refresh_token, tableId }));
		}
	}
}

function* logoutRequestSaga() {
	try {
		yield put(cleanUp());
		// yield put(deleteUser());
		yield call(api.auth.logout);
	} catch (error) {
		// onCatchError({ error });
	} finally {
		yield put(clearToken());
	}
}

function* refreshTokenSaga(action) {
	const { payload } = action;
	const { auth, tableId } = payload
	try {
		const response = yield call(api.auth.refreshToken, auth);
		yield put(loginSuccess({ auth: response.data, tableId }));
	} catch (error) {
		// onCatchError({ error });
		yield put(clearToken());
	}
}

function clearTokenSaga() {
	removeTokenFromLocalStorage();
	removeTableFromLocalStorage()
}

function* cleanUpSaga() {
	yield call(socketDisconnect);
}

function* setTableIdSaga(action) {
	const { payload } = action;
	yield saveTableIdToLocalStorage(payload);
}

export function* authSaga() {
	yield takeEvery(authTypes.LOGIN_REQUEST, loginRequestSaga);
	yield takeEvery(authTypes.LOGIN_SUCCESS, loginSuccessSaga);
	yield takeEvery(authTypes.LOGOUT_REQUEST, logoutRequestSaga);
	yield takeLatest(authTypes.REFRESH_TOKEN, refreshTokenSaga);
	yield takeEvery(authTypes.CLEAR_TOKEN, clearTokenSaga);
	yield takeEvery(authTypes.CLEAN_UP, cleanUpSaga);
	yield takeEvery(authTypes.SET_TABLE_ID, setTableIdSaga);
}
