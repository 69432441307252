import React from 'react'
import { Link } from 'react-router-dom'

export function MessageBlock(props) {
    const { isExit } = props
    return (
        <div className="message">
            <p className="welcome-message">{isExit ? 'Спасибо за работу!' : 'Здравствуйте!'}</p>
            <p className="info-message">{isExit ? 'Вы молодец, Ваш рабочий день завершен.' : 'Кассовая смена открыта, хорошего дня!'}</p>
            <div className='submit-message'>
                {isExit
                    ? <button>ДА</button>
                    : <Link to="/orders">
                        <button>ДА</button>
                    </Link>
                }
            </div>
        </div>
    )
}
