import moment from "moment";

export const parseFetchedOrders = (data) => {
    data.orders = data.orders.map(order => {
        order.delivery_time = moment(moment(order.delivery_time, 'HH:mm:ss').toDate()).format('HH:mm');
        order.orderTime = moment(order.delivery_date).add(order.delivery_time, 'hours').toDate();
        order.hotOrderTime = (order.orderTime - moment().toDate()) / 1000
        return order
    })
    return data
}

export const parseFetchedOrdersById = (ordersData) => {
    const data = {}
    data.data = ordersData.orders.map(order => {
        order.delivery_time = moment(moment(order.delivery_time, 'HH:mm:ss').toDate()).format('HH:mm');
        order.orderTime = moment(order.delivery_date).add(order.delivery_time, 'hours').toDate();
        order.hotOrderTime = (order.orderTime - moment().toDate()) / 1000
        return order
    })
    return data
}