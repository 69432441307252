import React, { useEffect, useState } from 'react'
import { workshopsIcons } from "constants/workshops"
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setOrderPacked } from 'store/orders/actions'

function OrderCard(props) {
    const { order, setOrderPacked } = props;
    const { delivery_time, workshops, day_id, id, hotOrderTime } = order;

    const [tap, setTap] = useState(1)
    const [orderStateTabClass, setOrderStateTabClass] = useState("")

    const onChangeTap = () => {
        let timeoutId;
        if (tap === 1) setOrderStateTabClass("tap1")
        if (tap === 2) {
            setOrderStateTabClass("tap2")
            timeoutId = setTimeout(() => {
                setOrderStateTabClass(prev => `${prev} tap2-finish`)
                setOrderPacked(id)
            }, 1000)
        }
        setTap(prev => ++prev)
        return () => clearTimeout(timeoutId)
    }

    const [isCardToClient, setIsCardToClient] = useState(false)

    useEffect(() => {
        let timeoutId;
        // 1259 -> Карточка у которой осталось менее 21 (1259 секунд) минут до вручения Клиенту подсвечиваются
        if (hotOrderTime < 1259) {
            setIsCardToClient(true)
        } else {
            timeoutId = setTimeout(() => {
                setIsCardToClient(true)
            }, (hotOrderTime - 1259) * 1000)
        }
        return () => clearTimeout(timeoutId)
    }, [hotOrderTime])

    return (
        <div
            className={`card ${orderStateTabClass} ${isCardToClient ? "order-toClient" : ""}`}
            onClick={onChangeTap}>
            <div className="card__top">
                <div className="card__id">{day_id || "---"}</div>
                <div className="card__time">
                    {delivery_time}
                </div>
            </div>
            <div className="card__bottom">
                <div className="card__workshops">
                    {workshops.map(workshop => {
                        return <img src={workshopsIcons[workshop]} alt={`${workshop}`} key={workshop} />
                    })}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = { setOrderPacked };

const EnhancedOrderCard = compose(
    connect(mapStateToProps, mapDispatchToProps),
)(OrderCard);

export { EnhancedOrderCard };