import japanese from 'assets/images/workshops/japanese.svg';
import market from 'assets/images/workshops/market.svg';
import pasta from 'assets/images/workshops/pasta.svg';
import pizza from 'assets/images/workshops/pizza.svg';
import assembly from 'assets/images/workshops/assembly.svg';

export const workshopsIcons = {
    "sushi-workshop": japanese,
    "market-workshop": market,
    "hot-workshop": pasta,
    "pizza-workshop": pizza,
    "assembly-workshop": assembly,
}