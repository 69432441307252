export const removeEmptyStrings = (obj = null) => {
	if (!obj) return;

	const newObj = {};

	Object.keys(obj).forEach(prop => {
		if (obj[prop] !== '') { newObj[prop] = obj[prop]; }
	});

	return newObj;
};