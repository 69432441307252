import * as orders from './types';

export const fetchOrders = (payload) => ({ type: orders.FETCH_ORDERS, payload });
export const saveOrders = (payload) => ({ type: orders.SAVE_ORDERS, payload });

export const setOrderPacked = (payload) => ({ type: orders.SET_ORDER_PACKED, payload });

export const fetchSearchedOrders = (payload) => ({ type: orders.FETCH_SEARCHED_ORDERS_LIST, payload });
export const saveSearchedOrders = (payload) => ({ type: orders.SAVE_SEARCHED_ORDERS_LIST, payload });

export const setStatusOfSearchedOrders = (payload) => ({ type: orders.CHANGE_SEARCHED_LIST_ACTIVE_STATUS, payload });
export const saveStatusOfSearchedOrders = (payload) => ({ type: orders.SAVE_SEARCHED_LIST_ACTIVE_STATUS, payload });

export const fetchCashboxStatus = () => ({ type: orders.FETCH_CASHBOX_STATUS });
export const saveCashboxStatus = (payload) => ({ type: orders.SAVE_CASHBOX_STATUS, payload });
