import React, { useState } from 'react'
import { Header, Popup, Modal } from 'components'
import 'assets/scss/index.scss';
import { StartSessionPage, EndSessionPage, OrdersListPage, LoginFormPage } from 'pages'
import { Redirect, Route, Switch } from 'react-router-dom';
import { getAuthIsAuthenticated } from 'store/auth/selectors';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getCashboxStatus } from 'store/orders/selectors';

function App(props) {
    const { isLogin, isCashboxOpen } = props
    const [settingModal, setSettingModal] = useState(false)

    if (!isLogin) {
        return <LoginFormPage />
    }

    return (
        <>
            {settingModal && <Modal setSettingModal={setSettingModal} />}
            <div className="container-app">
                <Header setSettingModal={setSettingModal} />
                <Popup header="У Вас пропало соединение!" content="Найдите подключение к интернету!" />
                <Switch>
                    <Route path="/start" exact render={() => isCashboxOpen ? <StartSessionPage /> : <Redirect to="/finish" />} />
                    <Route path="/orders" exact component={() => isCashboxOpen ? <OrdersListPage /> : <Redirect to="/finish" />} />
                    <Route path="/finish" component={() => !isCashboxOpen ? <EndSessionPage /> : <Redirect to="/start" />} />
                    <Redirect to="/start" />
                </Switch>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogin: getAuthIsAuthenticated(state),
        isCashboxOpen: getCashboxStatus(state),
    }
};

const mapDispatchToProps = {
};

const EnhancedApp = compose(
    connect(mapStateToProps, mapDispatchToProps),
)(App);

export { EnhancedApp };
