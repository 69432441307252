import { MessageBlock } from 'components'
import React from 'react'

export function StartSession() {
    return (
        <div className="main">
            <MessageBlock />
        </div>
    )
}
