import React from 'react'
import waitingPic from 'assets/images/waiting-pic.svg'

export function OrderWaiting() {
    return (
        <div className="waiting-order">
            <div className="waiting-container">
                <div className="waiting-pic">
                    <img src={waitingPic} alt="logo" />
                </div>
                <div className="waiting-messsage">Ожидайте заказы к сборке</div>
            </div>
        </div>
    )
}