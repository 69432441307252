import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;
const SOCKET_DEBUG = process.env.NODE_ENV === 'development';
const PREFIX_PRIVATE = '';
const PREFIX_ENCRYPTED = 'encrypted-';
const IS_ENCRYPTED = process.env.REACT_APP_WS_ENCRYPTED && process.env.REACT_APP_WS_ENCRYPTED === 'true';

const WS = {
	echo: null,
	channels: {}
};

WS.subscribe = (channelName) => {
	channelName = PREFIX_PRIVATE + (IS_ENCRYPTED ? PREFIX_ENCRYPTED : '') + channelName;
	return new Promise(resolve => {
		if (WS.echo && WS.echo.connector.pusher.connection.state === 'connected') {
			if (!WS.channels[channelName]) {
				const pusherChannel = WS.echo.connector.pusher.subscribe(channelName);
				pusherChannel.bind('pusher:subscription_succeeded', function () {
					if (SOCKET_DEBUG) console.log(`socket subscribed '${channelName}'`);
					WS.channels[channelName] = pusherChannel;
					resolve(WS.channels[channelName]);
				});
				pusherChannel.bind('pusher:subscription_error', function () {
					if (SOCKET_DEBUG) console.error(`socket not subscribed '${channelName}'`);
					WS.channels[channelName] = null;
					resolve(null);
				});
			} else {
				resolve(WS.channels[channelName]);
			}
		} else {
			resolve(null);
		}
	});
};

WS.unsubscribe = async (channelName) => {
	channelName = PREFIX_PRIVATE + (IS_ENCRYPTED ? PREFIX_ENCRYPTED : '') + channelName;
	if (WS.echo && WS.echo.connector.pusher.connection.state === 'connected') {
		if (WS.channels[channelName]) {
			await WS.echo.connector.pusher.unsubscribe(channelName);
			WS.channels[channelName] = null;
			if (SOCKET_DEBUG) console.log(`socket unsubscribed '${channelName}'`);
		}
	}
};

WS.emit = (channelName, eventName, data) => {
	channelName = PREFIX_PRIVATE + (IS_ENCRYPTED ? PREFIX_ENCRYPTED : '') + channelName;
	if (WS.channels[channelName]) {
		if (SOCKET_DEBUG) console.log(`socket emit '${eventName}'`, data);
		WS.channels[channelName].trigger(eventName, data);
	}
};

WS.on = (channelName, eventName, callback) => {
	channelName = PREFIX_PRIVATE + (IS_ENCRYPTED ? PREFIX_ENCRYPTED : '') + channelName;
	if (WS.channels[channelName]) {
		WS.channels[channelName].bind(eventName, (response) => {
			if (SOCKET_DEBUG) console.log(`socket on '${eventName}'`, response);
			callback(response);
		});
	}
};

WS.off = async (channelName, eventName) => {
	channelName = PREFIX_PRIVATE + (IS_ENCRYPTED ? PREFIX_ENCRYPTED : '') + channelName;
	if (WS.channels[channelName]) {
		await WS.channels[channelName].unbind(eventName);
		if (SOCKET_DEBUG) console.log(`socket off '${eventName}'`);
	}
};

WS.disconnect = async () => {
	if (WS.echo && WS.echo.connector.pusher.connection.state === 'connected') {
		await WS.echo.connector.pusher.disconnect();
		WS.channels = {};
	}
};

export const ws = (tokenType, accessToken) => {
	return new Promise(resolve => {
		if ((!WS.echo && !!tokenType && !!accessToken) || (!!WS.echo && !!tokenType && !!accessToken)) {
			const options = {
				broadcaster: 'pusher',
				key: process.env.REACT_APP_WS_PUSHER_KEY,
				wsHost: process.env.REACT_APP_WS_API_URL,
				wsPort: process.env.REACT_APP_WS_API_PORT,
				authEndpoint: process.env.REACT_APP_WS_API_AUTH,
				disableStats: true,
				enabledTransports: ['ws', 'wss'],
				auth: {
					headers: {
						'Authorization': `${tokenType} ${accessToken}`,
						'Accept': 'application/json'
					}
				}
			};

			if (IS_ENCRYPTED) {
				options.encrypted = true;
			}

			WS.echo = new Echo(options);
			WS.echo.connector.pusher.connection.bind('connected', () => {
				if (SOCKET_DEBUG) console.log('socket connected');
				resolve(WS);
			});
			WS.echo.connector.pusher.connection.bind('error', (error) => {
				if (SOCKET_DEBUG) console.log('socket error', error.error.data.message);
				WS.echo = null;
				WS.channels = {};
				resolve(WS);
			});
			WS.echo.connector.pusher.connection.bind('disconnected', () => {
				if (WS.echo) {
					if (SOCKET_DEBUG) console.log('socket disconnected');
					WS.echo = null;
					WS.channels = {};
				}
			});
		} else {
			resolve(WS);
		}
	});
};
