import * as auth from './types';

export const loginRequest = (payload) => ({type: auth.LOGIN_REQUEST, payload});
export const loginSuccess = (payload) => ({type: auth.LOGIN_SUCCESS, payload});

export const refreshTokenRequest = (payload) => ({type: auth.REFRESH_TOKEN, payload});
export const clearToken = () => ({type: auth.CLEAR_TOKEN});
export const cleanUp = () => ({type: auth.CLEAN_UP});

export const setTableId = (payload) => ({type: auth.SET_TABLE_ID, payload});

export const logoutRequest = () => ({type: auth.LOGOUT_REQUEST});
