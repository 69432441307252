import { OrderWaiting, OrderCard, NotFoundSearch } from 'components'
import React from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getOrders, getSearchedOrdersData, getSearchedOrdersStatus } from 'store/orders/selectors';

function OrdersList(props) {
    const { listOrder, isActiveSearch } = props;

    return listOrder.length ?
        <div className="orders-list">
            {listOrder.map((order, i) => (<OrderCard order={order} key={order.id} />))}
        </div>
        : isActiveSearch ? <NotFoundSearch /> : <OrderWaiting />
}

const mapStateToProps = (state) => {
    const isActiveSearch = getSearchedOrdersStatus(state);
    const list = isActiveSearch ? getSearchedOrdersData(state) : getOrders(state);
    return {
        listOrder: list,
        isActiveSearch
    }
};

const mapDispatchToProps = {};

const EnhancedOrdersList = compose(
    connect(mapStateToProps, mapDispatchToProps),
)(OrdersList);

export { EnhancedOrdersList };