import { put, takeEvery, call, select, delay, takeLatest } from 'redux-saga/effects'
import { api } from "services/api";
import { saveCashboxStatus, saveOrders, saveSearchedOrders, saveStatusOfSearchedOrders } from "./actions";
import * as orders from './types';
import { getTableId } from 'store/auth/selectors'

function* fetchOrdersSaga() {
    const tableId = yield select(getTableId)
    try {
        const results = yield call(api.orders.fetchOrders, { tablet_id: tableId });
        yield put(saveOrders(results))
    } catch (e) {
        console.error(e)
    }
}

function* fetchSearchedOrdersSaga(action) {
    const { payload } = action
    try {
        yield delay(500)
        const results = yield call(api.orders.fetchSearchedOrders, payload);
        yield put(saveSearchedOrders(results))
    } catch (e) {
        console.error(e)
    }
}

function* saveActiveStatusOfSearchedOrders(action) {
    const { payload } = action
    try {
        yield delay(500)
        yield put(saveStatusOfSearchedOrders(payload))
    } catch (e) {
        console.error(e)
    }
}

function* setOrderPackedSaga(action) {
    const { payload } = action
    try {
        yield call(api.orders.setOrderPacked, payload);
        // yield put(fetchOrders())
    } catch (e) {
        console.error(e)
    }
}

function* fetchCashboxStatusSaga() {
    try {
        const results = yield call(api.orders.fetchCahsboxStatus);
        yield put(saveCashboxStatus(results))
    } catch (e) {
        console.error(e)
    }
}

export function* ordersSaga() {
    yield takeEvery(orders.FETCH_ORDERS, fetchOrdersSaga)
    yield takeEvery(orders.SET_ORDER_PACKED, setOrderPackedSaga)
    yield takeEvery(orders.FETCH_CASHBOX_STATUS, fetchCashboxStatusSaga)
    yield takeLatest(orders.FETCH_SEARCHED_ORDERS_LIST, fetchSearchedOrdersSaga)
    yield takeLatest(orders.CHANGE_SEARCHED_LIST_ACTIVE_STATUS, saveActiveStatusOfSearchedOrders)
}