import { http } from 'services';
import { parseFetchedOrders, parseFetchedOrdersById } from './parse';
import { removeEmptyStrings } from 'utils/common'

export const orders = {
    fetchOrders: (payload) => http.get('/collector/orders', { params: removeEmptyStrings(payload) }).then((response) => {
        return response.data.data ? parseFetchedOrders(response.data.data) : null
    }),
    fetchSearchedOrders: (payload) => http.get('/collector/orders', { params: { day_id: payload } }).then((response) => {
        return response.data.data ? parseFetchedOrdersById(response.data.data) : null
    }),
    setOrderPacked: (payload) => http.put(`/collector/orders/${payload}`).then((response) => {
        return response
    }),
    fetchCahsboxStatus: () => http.get(`/collector/cashbox-status`).then((response) => {
        return response.data.opened
    }),
};