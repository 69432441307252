import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getAuthIsLoggingIn, getLoggingFail } from 'store/auth/selectors'
import { loginRequest } from 'store/auth/actions';
import tablet from 'assets/images/choice-tablet.png';

function LoginForm(props) {
    const { loginRequest, pending, error } = props

    const [password, setPassword] = useState("")
    const [login, setLogin] = useState("")
    const [tableId, setTableId] = useState()

    const tablets = []
    for (let i = 0; i < (tableId > 4 ? tableId : 4); i++) {
        tablets[i] = (
            <div
                key={i}
                className="tablet"
                style={{ filter: tableId === i + 1 ? "sepia(1)" : "unset" }}
                onClick={() => {
                    setTableId(i + 1)
                }}>
                <div className="tablet__num">{i + 1}</div>
                <img src={tablet} alt="tablet" />
            </div>
        )
    }

    const onSumbit = (e) => {
        e.preventDefault()
        password && login && tableId && loginRequest({ auth: { password, login }, tableId })
    }

    return (
        <div className="login">
            <div className="container">
                <div className="row justify-content-center align-items-center text-center p-2">
                    <div className="m-1 col-sm-8 col-md-6 col-lg-4 shadow-sm p-3 mb-5 bg-white border rounded">
                        <div className="pt-4 pb-4">
                            <form className="form text-center" onSubmit={onSumbit}>
                                <div className={`form-group input-group-md`}>
                                    <input
                                        className={`form-control ${error ? "error" : ""}`}
                                        value={login}
                                        onChange={(e) => setLogin(e.target.value)}
                                        placeholder="Введите логин"
                                    />
                                    {/* <div className="invalid-feedback">
                                        Неверный пароль
                                    </div> */}
                                    {pending && <div className="loading-request">
                                        loading...
                                    </div>}
                                </div>
                                <div className="form-group input-group-md">
                                    <input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type="password"
                                        className={`form-control ${error ? "error" : ""}`}
                                        id="password"
                                        placeholder="Введите пароль"
                                    />
                                    {/* <div className="invalid-feedback">
                                        Неверный пароль
                                    </div> */}
                                    {pending && <div className="loading-request">
                                        loading...
                                    </div>}
                                </div>
                                <div style={{ fontSize: 22 }}>Выберите планшет</div>
                                <div className="tablets">
                                    {tablets}
                                </div>
                                <button className="btn btn-lg btn-block btn-primary mt-4" type="submit">
                                    Войти
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        pending: getAuthIsLoggingIn(state),
        error: getLoggingFail(state),
    }
};

const mapDispatchToProps = { loginRequest };

const EnhancedLoginForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
)(LoginForm);

export { EnhancedLoginForm };